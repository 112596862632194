<template>
  <div class="website__domain">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-space-between page-header">
        <h2 class="page-title">Size charts</h2>
        <router-link :to="{ name: 'website.sizechart.add' }" class="my-auto mx-0 text-decoration-none">
          <v-btn class="" color="primary">Add size chart</v-btn>
        </router-link>
        <!-- <router-link :to="{ name: 'website.sizechart.testdialog' }" class="my-auto text-decoration-none">
          <v-btn color="primary">Go to test dialog</v-btn>
        </router-link> -->
      </v-col>
    </v-row>
    <div class="elevation-form pa-3">
      <span
        >We'll add a small widget to display specified size chart when your customers select products in store. Enabling
        size charts in your store can help convert more sales and improve customers' experience.
      </span>
      <span class="showPreview primary--text" @click="showPreview = true">Preview size chart widget here.</span>
      <!-- <div class="d-flex mt-4">
        <v-switch class="mt-1" v-model="isSwitch" inset color="primary"></v-switch>
        <div class="">
          <div>{{ isSwitch == true ? 'Enable' : 'Diable' }} size chart widget</div>
          <div class="font-italic">
            The size chart widget will be displayed beside 'Size' option or above 'Add to cart' button
          </div>
        </div>
      </div> -->
      <v-divider class="mt-5"></v-divider>
      <Resource
        :document="document"
        :filter-fields="null"
        :filter-data="filterData"
        :filter-callback="setFilterData"
        :searchable="searchableField"
        :columns="columns"
        :update-router-callback="getUpdateRouterData"
        :actionItems="items"
        :callback-action="getAction"
        :callback-selection="getIds"
        :routeName="updateRouteName"
      />
      <v-col cols="12" class="d-flex justify-space-between pa-0">
        <limit-page :callback="setLimit" :page.sync="page" />
        <pagination :length="100000" :visible="0" :page.sync="page"></pagination>
      </v-col>
    </div>
    <v-dialog v-model="showPreview" width="500">
      <v-card>
        <v-card-title class="headline lighten-2"> Preview Image </v-card-title>
        <v-card-text>
          <v-img max-height="600" contain src="@/assets/images/logo.jpg"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showPreview = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showModalDelete" width="500" style="transform-origin: center top">
      <v-card relative>
        <v-card-title class="headline"> {{ 'Delete' }} {{ ids.length }} product type?. </v-card-title>
        <div class="close-icon" @click="showModalDelete = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-card-text class="">
          Are you sure you want to delete {{ ids.length }} product type. This action cannot be reversed.
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalDelete = false"> Cancel </v-btn>
          <v-btn :loading="isLoadingButton" color="error" @click="onSubmitDelete"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import datetime from '@/helpers/datetime';
import filter from '@/const/filters/product-filter';
import resourceListMixin from '@/mixins/resource-list';
import Resource from '@/components/Resources';
import { sizechartApi } from '@/apis/sizecharts';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      items: [{ title: 'Delete selected products type', action: 'remove-product' }],
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },
        'Product type': {
          // blankLabel: true,
          kind: 'title-sizechart',
          field: 'tag',
        },
        STYLE: {
          kind: 'title',
          field: 'style',
        },
      },
      ids: [],
      products: [],
      length: 1,
      isSwitch: true,
      showPreview: false,
      showModalDelete: false,
      isLoadingButton: false,
      primaryModal: false,
      disableRedirection: false,
      isLoading: false,
      domains: [],
      errors: [],
      datetime,
      resourceType: 'sizechart',
      // page: 1,
      tabs: [
        // { name: 'All', key: '' },
        // { name: 'Open', key: 'published' },
        // { name: 'Unfulfilled and partially fulfilled', key: 'unpublished' },
        // { name: 'Chargeback and inquiry', key: 'unpublisheds' },
      ],
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      testVuex: null,
      // searchableField: 'title_search',
      searchableField: false,
      updateRouteName: 'website.sizechart.update',
      sortable: [],
      filter,
    };
  },
  methods: {
    async loadProducts() {
      let params = {
        page: 1,
        limit: 50,
      };
      try {
        params.search = this.search;
        let data = await sizechartApi.get(params);
        this.products = data.data;
        console.log(this.products);
      } catch (error) {
        console.log(error);
      }
      console.log(this.search);
    },
    async onSubmitDelete() {
      try {
        this.isLoadingButton = true;
        await sizechartApi.removeById(`?ids=${this.ids.join(',')}`);
        this.isLoading = true;
        this.showModalDelete = false;

        await this.getResources();
        this.isLoading = false;
      } catch (error) {
        //empty
      }
      this.showModalDelete = false;
      this.isLoadingButton = false;
    },
    getIds(ids) {
      this.ids = ids;
    },
    getAction(action, ids) {
      this.ids = ids;
      this.action = action.action;
      if (action.action === 'bulk-edit') {
        this.$router.push({
          name: 'website.products.bulkEdit',
          query: {
            ids: ids.join(','),
          },
        });
      }
      if (action.action === 'remove-product') {
        this.showModalDelete = true;
      }
    },
  },
  watch: {
    search: function() {
      this.loadProducts();
    },
  },
};
</script>
<style lang="scss">
.website__domain {
  .connected {
    background-color: var(--v-primary-base);
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
  }
  .disconnected {
    background-color: #ddd;
    padding: 5px 10px;
    border-radius: 10px;
  }
}
.showPreview:hover {
  cursor: pointer;
  text-decoration: underline;
}
.il {
  display: inline;
}
</style>
